.Home {
	display: grid;
}

.Home .container-logo {	
	display: grid;

	height: calc(var(--min-u) * 80 - 7rem - 2rem);
	width: calc(90vw - 4rem);

	align-content: center;
	justify-content: center;
	justify-self: center;
}
.Home .container-logo>img {
	width: 100%;
	height: auto;
	align-content: center;

	filter: drop-shadow(0 0 1em black);
}

.Home .container-paragraph p {
	font-family: "Object Sans";
	width: 40%;
	justify-self: center;
}
@media only screen and (max-width: 600px) {
	.Home .container-paragraph p {
		width: unset;
		margin: 0 1rem;
	}
}

.Home .container-paragraph {
	align-self: center;
	align-content: start;
	display: grid;
}
@media only screen and (max-width: 600px) {
	.Home .container-paragraph {
		margin: 2rem 0 2rem;
	}
}


/* Member-Cards */
.Home .container-membercards {
	display: flex;
	justify-content: center;
	overflow-x: wrap;
}
@media only screen and (max-width: 600px) {
	.Home .container-membercards {
		margin-top: 1rem;
		display: grid;
		gap: 1rem;
	}
}

.Home .MemberCard {
	position: relative;
	display: flex;

	justify-self: center;

	margin: 0 1rem;

	width: fit-content;
	height: fit-content;

	transition: .7s;
}

.Home .MemberCard:hover {
	margin-right: 5rem;
}
.Home .MemberCard>.container {
	display: flex;
	position: relative;
	z-index: 1;

	height: 4rem;

	background-color: #4108ff;
	color: black;

	padding: 0 1rem;
	border-radius: 1rem;

	transition: .3s;
}
.Home .MemberCard:hover>.container {
	color: white;
}
@media only screen and (max-width: 600px) {
	.Home .MemberCard {
		width: 100%;
	}
	.Home .MemberCard>.container {
		width: 100%;
		display: grid;
		grid-template-columns: 2rem auto;
	}
	.Home .MemberCard:active>.container {
		transform: scale(1.05);
		color: white;
		background-color: #6026ff;
	}
	.Home .MemberCard>.container>.name {
		justify-self: center;
		font-size: 1.5rem;
	}
}

.Home .MemberCard>.container>.container-icon {
	align-self: center;
	margin-right: 1rem;
	transform: translateY(.15rem);
}
.Home .MemberCard>.container>h1 {
	font-family: "Object Sans";
	align-self: center;
}
.Home .MemberCard>a {
	position: absolute;
	background-color: #6026ff;
	width: 9rem;
	height: 4rem;
	display: grid;
	border-radius: 0 1rem 1rem 0;
	right: 0;
	z-index: 0;
	transition: .7s;
	color:black;
}
.Home .MemberCard:hover>a {
	transform: translateX(4rem);
	color: white;
}
.Home .MemberCard>a>.container-icon {
	align-self: center;
	justify-self: right;
	margin-right: 1.5rem;
	transform: translate(.5rem, .15rem);
}
@media only screen and (max-width: 600px) {
	.Home .MemberCard>a {
		left: 0;
		width: 100%;
		background-color: rgba(0,0,0,0);
		z-index: 1;
	}
	.Home .MemberCard>a>.container-icon {
		display: None;
	}
	.Home .MemberCard:hover {
		transform: scale(1.05);
	}
}

/* Projects */
.Home .container-projects {
	display: grid;
	gap: 2rem;

	width: 100%;
	justify-self: center;

	--height: calc(100vh - 26rem);
	height: var(--height);
	max-height: var(--height);

	border: 1px #ffffff00 dashed;
}

@media only screen and (max-height: 650px) {
	.Home .container-projects {
		height: unset;
		max-height: unset;
	}
}