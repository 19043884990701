html {
  --min-u: min(1vw, 1vh);
  --max-u: max(1vw, 1vh);

  --min-u100: min(100vw, 100vh);
  --min-u100: min(100vw, 100vh);

  width: 100vw;  

  background: black;
  overflow-x: hidden;

  scroll-behavior: smooth;

  color: white;
}

html::before {
  content: "";
  position: fixed;
  z-index: -10;
  top: -100vh;
  left: -100vw;
  width: 200vw;
  height: 200vh;
  background: radial-gradient(circle, #0d1117 10%, transparent 10.01%),
    radial-gradient(circle, transparent 20%, #0d1117 20.01%);
  background-size: 2.5rem 2.5rem; /* Adjust the size of the pattern */
  animation: moveBackground 25s linear infinite; /* Adjust the animation duration and timing function */
}

@keyframes moveBackground {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(50vh, 50vw);
  }
}

a {
  text-decoration: none;
  color: white;
}

.lt, .gt {
  font-family: "Silkscreen";
  font-weight: bolder;

  color: #ff0a43;
  /* #4108ff */
}
