.Spacer {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	padding: 2rem;
	right: 3rem;
	display: grid;
	width: 10%;
}

.Spacer>div {
}

.Spacer>div>svg{
	border-radius: 100rem;
	background-color: #ffffff20;

	justify-self: center;
	align-self: center;
	transition: .2s;
	color: black;
}
.Spacer[clicked="true"]>div>svg {
	transform: rotate(180deg);
}

@media only screen and (max-height: 650px) {
	
}