.Topbar {
	background-color: black;
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 5rem;

	color: white;

	display: flex;
	justify-content: left;
}

.Topbar>.element {
	margin: 0 2rem;	
	align-self: center;
}

.topbar-brand {
	font-family: "Silkscreen";
}

@media only screen and (max-width: 600px) {
 	.Topbar {
 		justify-content: center;
 	}
 	.Topbar>.element {
 		margin: 0 1rem;
 	}
 	.Topbar>.element>*:is(h1, h2, h3, h4, h5, h6, p) {
 		font-size: 1.75rem;
 	}
}