
.Projects {
	position: relative;
	display: grid;
	gap: 0;

	overflow: hidden;
}

.Project {
	display: grid;
	position: relative;
	
	grid-template-columns: 8rem auto;
	justify-self: center;
	justify-content: left;

	height: 7.5rem;
	width: 30rem;
	max-height: 7.5rem;

	transition: .4s, width 1s, height .5s;
}
.Project::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	
	border-radius: 2rem;
	background-color: #4108ff;

	z-index: 0;

}
.Project>.container-icon>img {
	position: absolute;

	width: 3.5rem;

	left: 50%;
	top: 50%;
	transform: translate(-50%, calc(-50% + .75rem));

	filter: drop-shadow(5px 5px 5px #000000a0);

	z-index: 2;
}
.Project>.container-icon {
	position: relative;
	display: flex;

    width: 5rem;
    height: 5rem;

    margin: .75rem;
    padding: .5rem;

    z-index: 2;
    transition: transform .5s;
}
.Project>.container-icon::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	
	border-radius: 100rem;
	background-color: #5324ff;

	z-index: 1;
}
.Project>.container-description {
	padding-right: 2rem;
	text-align: left;
	z-index: 2;
}
.Project>.container-description>h1 {
	font-family: "Object Sans";
	color: black;
	margin: 1rem 0;
}

.Project>.container-description>p {
	font-family: "Object Sans";
	color: black;
	overflow: hidden;
	white-space: nowrap;
	max-width: 100%;
	transition: max-width 1s, transform .1s;
}

.Project:hover {
	transform: translateX(-2rem);
}

.Project>a {
	position: absolute;
	top: 0;
	right: 0;

	height: 7.5rem;
	width: 10rem;

	border-radius: 0 2rem 2rem 0;

	background-color: #5324ff;
	z-index: -1;

	transition: .4s, width 2.5s, height 1s, background-color 2.5s;
}

.Project:hover>a {
	transform: translateX(6rem);
}

.Project>a>svg {
	position: relative;
	color: black;
	margin: 1.5rem 0;

	transition: .4s, opacity 2s;

	opacity: 0;

	transform: translateX(1.75rem);
}

.Project:hover>a>svg {
	opacity: 100%;
}

@media only screen and (min-width: 699px) {
	.Project[prev="true"] {
		position: relative;
		display: grid;
		grid-template-columns: 0 auto;

		padding: 0;
		justify-self: center;
		justify-content: center;

		width: 15rem;
		height: 3rem;

		color: black;
		background-color: #ffffff20;
		border-radius: 100rem;

		cursor: pointer;

		transition: .2s;
	}
	.Project[prev="true"]:hover {
		transform: scale(1.05);
	}
	.Project[prev="true"]::before {
		display: None;
	}
	.Project[prev="true"]>.container-description>p {
		transform: scale(0);
		max-width: 0;
	}
	.Project[prev="true"]>a {
		transition: 0s;
		height: 0;
		background-color: #00000000;
		opacity: 0;
	}
	.Project[prev="true"]>.container-icon{
		opacity: 0;
		width: 0;
		height: 0;
		transform: scale(0);
	}

	.Project[prev="true"]>.container-description {
		display: grid;

		pointer-events: none;

		text-align: center;
		justify-self: center;

		height: auto;
		margin-top: -.5rem;
		padding: 0;
	}

	.Project[prev="true"] h1 {
		justify-self: center;
		align-self: center;

		font-size: 1.5rem;
		font-family: "Object Sans";
	}
}

/* mobile etc. */
@media only screen and (max-width: 700px) {
	.Projects {
		gap: .5rem;
		overflow: visible;
	}
	.Project {
		grid-template-columns: 4rem auto;
		
		width: 15rem;
		height: 4rem;
	}
	.Project:hover {
		transform: scale(1.05);
	}
	.Project::before {
		display: flex;
		
		border-radius: 100rem;
	}
	.Project>.container-icon {
		display: grid;

		height: 2rem;
		width: 2rem;

		margin: .5rem;
	}
	.Project>.container-icon>img {
		display: grid;

		width: 2.1rem;
		
		transform: translate(-50%, calc(-50% + .5rem));
	}
	.Project>.container-description {
		padding: 0;
	}
	.Project>.container-description>h1 {
		font-size: calc(2em * .6);
	}
	.Project>.container-description>p {
		display: None;
	}
	.Project>a {
		left: 0;
		top: 0;
		
		height: 100%;
		width: 100%;

		background-color: #00000000;
		border-radius: 100rem;

		z-index: 2;
	}
	.Project:hover>a {
		transform: unset;
	}
	.Project>a>svg {
		display: None;
	}
}