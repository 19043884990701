.grid-section {
  position: relative;
  display: grid;
  padding: 2rem;
  width: calc(100% - 6rem);
  --height: calc(100vh - 20rem);
  min-height: var(--height);
  height: var(--height);
  max-height: var(--height);
  left: 50%;
  transform: translateX(-50%);
  align-self: center;
  align-content: center;
}

@media only screen and (max-height: 650px) {
  .grid-section {
    height: unset;
    max-height: unset;
  }
}